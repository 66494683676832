import { Button, useDisclosure } from "@nextui-org/react";
import { MetaFunction } from "@remix-run/cloudflare";
import { useSearchParams } from "@remix-run/react";
import { useCallback, useEffect, useMemo } from "react";

import Navbar from "~/components/navbar";
import Provider, { ProviderSkeleton } from "~/components/provider";
import { useSearch } from "~/stores/search";

import { useUser } from "~/stores/user";
import Search from "~/components/search";
import { HiSparkles } from "react-icons/hi2";
import IdeasDialog from "./components/ideas-dialog";
import posthog from "posthog-js";

export const meta: MetaFunction = ({ location }) => {
  const q = new URLSearchParams(location.search).get("q");

  return [
    { title: `${q} - Search` },
    {
      name: "robots",
      content: "noindex, follow",
    },
    {
      name: "googlebot",
      content: "noindex, follow",
    },
    {
      name: "description",
      content:
        "Discover India's top service providers on Tribe! From photographers to interior designers and fitness trainers, find the perfect match for your needs today.",
    },
  ];
};

export default function SearchPage() {
  const [searchParams] = useSearchParams();
  const { location } = useUser();
  const { search, results, loading, hasMore, ideas, ideasLoading, loadIdeas } =
    useSearch();
  const { isOpen, onOpenChange } = useDisclosure();

  const q = searchParams.get("q");

  const highConfidenceResults = useMemo(() => {
    return results.filter(
      (provider) =>
        provider.confidence === "high" &&
        (provider.images === undefined || provider.images.length > 0)
    );
  }, [results]);

  useEffect(() => {
    const init = async () => {
      if (!location) {
        // Wait for location to load
        return;
      }

      if (!q) {
        // TODO: redirect to home
        return;
      }

      search(q, 0, location);
    };

    init();
  }, [location, q, search]);

  const loadMore = useCallback(() => {
    if (hasMore && q && location) {
      search(q, results.length, location);
    }
  }, [hasMore, q, location, search, results.length]);

  useEffect(() => {
    const load = async () => {
      if (!q) {
        return;
      }

      if (
        ideas.length === 0 &&
        !ideasLoading &&
        highConfidenceResults.length > 0
      ) {
        const top30 = highConfidenceResults.slice(0, 30);
        const provider_ids = top30.map((r) => r.provider_id);

        await loadIdeas(
          q,
          provider_ids,
          location || {
            lat: 12.9716,
            lng: 77.5946,
            label: "Bangalore, India",
          }
        );
      }
    };

    load();
  }, [
    highConfidenceResults,
    ideas,
    ideasLoading,
    isOpen,
    loadIdeas,
    location,
    q,
    results,
  ]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center">
        <div className="w-full max-w-7xl flex flex-col items-center">
          <div className="sticky w-full top-0 z-20 pt-2 sm:pt-6 pb-12 flex justify-center px-4 sm:px-6 bg-gradient-to-b from-background via-background to-transparent">
            <div className="max-w-xl w-full">
              <Search placeholder="Refine your search..." />
            </div>
          </div>
          <div className="w-full min-h-[calc(100dvh-64px)] py-6 flex flex-col px-4 sm:px-6 gap-8 -mt-6">
            {!loading && highConfidenceResults.length === 0 && (
              <div className="flex-1 flex flex-col justify-center items-center py-10 gap-8">
                <p className="text-4xl font-bold text-default-500 text-center">
                  Oops!
                </p>
                <p className="text-center mt-2">
                  No results found for &quot;{q}&quot;.
                </p>
              </div>
            )}

            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 px-2">
              {highConfidenceResults.map((provider) => {
                return (
                  <div key={provider.slug}>
                    <Provider
                      key={provider.slug}
                      {...provider}
                      q={q}
                      differentiator=""
                      offerings={provider.offerings}
                      images={provider.images || []}
                      confidence={provider.confidence}
                      direction="horizontal"
                    />
                  </div>
                );
              })}

              {loading &&
                Array.from({ length: 10 }).map((_, i) => (
                  <ProviderSkeleton key={i} />
                ))}
            </div>

            {highConfidenceResults.length > 0 && (
              <div className="flex space-x-2 items-center justify-center">
                {hasMore ? (
                  <Button isLoading={loading} variant="flat" onPress={loadMore}>
                    Load More
                  </Button>
                ) : (
                  <p className="text-sm text-center text-default-500">
                    No more results.
                  </p>
                )}
              </div>
            )}
          </div>
          {highConfidenceResults.filter((r) => r.confidence === "high").length >
            0 && (
            <div className="animate-fade w-full flex justify-end px-6 sticky bottom-10 md:bottom-10 z-50">
              <button
                className="relative flex flex-col justify-center items-center rounded-full size-16 aspect-square shadow-2xl bg-primary-50/80 backdrop-blur-xl ring-2 ring-primary-500 active:scale-80 transition-transform"
                onClick={() => {
                  onOpenChange();
                  posthog.capture("ideas_dialog_opened", {
                    query: q,
                    location_label: location?.label,
                  });
                }}
              >
                <HiSparkles className="size-8 text-primary-500" />
                <div className="absolute -top-3 -right-2 p-1.5 rounded-full bg-primary-500 shadow-xl">
                  <span className="block  text-xs italic font-bold text-primary-foreground">
                    Ideas
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <IdeasDialog
        isOpen={isOpen}
        onOpenChange={(open) => {
          onOpenChange();
          if (!open) {
            posthog.capture("ideas_dialog_closed", {
              query: q,
              location_label: location?.label,
            });
          }
        }}
      />
    </>
  );
}
