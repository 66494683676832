import {
  Button,
  cn,
  Modal,
  ModalBody,
  ModalContent,
  Skeleton,
} from "@nextui-org/react";
import { useCallback, useState } from "react";
import { HiSparkles } from "react-icons/hi2";
import { LuSearch, LuX } from "react-icons/lu";
import { EffectCards, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "~/components/logo";
import { useSearch } from "~/stores/search";
import { getPublicAssetURL } from "~/utils/assets";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-cards";
import "swiper/css/virtual";
import { useNavigate } from "@remix-run/react";
import posthog from "posthog-js";

export default function IdeasDialog({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { ideas, ideasLoading } = useSearch();

  const [current, setCurrent] = useState(0);

  const onSearchPress = useCallback(
    async (mergedQuery: string, idea: string) => {
      navigate(`/search?q=${mergedQuery}`);
      onOpenChange(false);
      posthog.capture("idea_search", {
        query: mergedQuery,
        idea: idea,
      });
    },
    [navigate, onOpenChange]
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      backdrop="blur"
      classNames={{
        backdrop: "bg-black bg-opacity-50 backdrop-blur-xl",
      }}
      placement="top"
      closeButton={
        <Button
          variant="light"
          isIconOnly
          startContent={<LuX className="text-white size-5" />}
        />
      }
    >
      <ModalContent className="bg-transparent shadow-none px-0 rounded-none m-0">
        <ModalBody className="flex justify-center items-center px-0 pt-28">
          <div className="flex flex-col items-center gap-2">
            <div className="bg-background rounded-full shadow-xl flex items-center justify-center py-2 px-3 gap-2">
              <HiSparkles className="size-5 text-primary" />

              <h3 className="text-xl font-medium">Ideas for you</h3>
            </div>
          </div>

          {ideasLoading && ideas.length === 0 && (
            <div className="flex flex-col items-center gap-4 w-full mt-8">
              <div className="relative w-full flex flex-col items-center justify-center">
                <Skeleton className="w-[62.5%] h-full aspect-square rounded-2xl " />
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <Logo className="h-40 w-40" animating />
                </div>
              </div>

              <Skeleton className="w-44 h-12 aspect-square rounded-full" />
              <Skeleton className="w-[62.5%] h-16 aspect-square rounded-2xl" />
            </div>
          )}

          {ideas.length > 0 && (
            <>
              <Swiper
                modules={[EffectCoverflow, Navigation, EffectCards]}
                className="w-full mt-8"
                slidesPerView={1.6}
                onSwiper={(swiper) => setCurrent(swiper.activeIndex)}
                onSlideChange={(swiper) => {
                  setCurrent(swiper.activeIndex);
                  posthog.capture("idea_changed", {
                    idea: ideas[swiper.activeIndex].title,
                    index: swiper.activeIndex,
                  });
                }}
                centeredSlides
                spaceBetween={-20}
              >
                {ideas.map((idea, index) => (
                  <SwiperSlide
                    key={idea.title}
                    virtualIndex={index}
                    className="rounded-lg"
                  >
                    {({ isActive }) => (
                      <div
                        className={cn(
                          "w-full relative transition-all flex flex-col items-center justify-center gap-4",
                          !isActive && "scale-90 grayscale"
                        )}
                      >
                        <img
                          className={cn(
                            "w-full h-auto aspect-square object-cover rounded-xl",
                            !isActive && "scale-80"
                          )}
                          src={getPublicAssetURL(idea.image || "")}
                          alt={idea.title}
                        />

                        {isActive && (
                          <>
                            <Button
                              size="lg"
                              variant="shadow"
                              color="primary"
                              radius="full"
                              startContent={<LuSearch className="size-5" />}
                              className="animate-fade text-lg transition-all"
                              onPress={() =>
                                onSearchPress(idea.mergedQuery, idea.title)
                              }
                            >
                              {idea.title}
                            </Button>

                            <div className="animate-fade bg-background rounded-xl p-3">
                              <p className="text-sm text-center line-clamp-2">
                                {ideas[current]?.description}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
